const data = [
  {
    title: "What is Ultra-Sound Dollar?",
    text: "USD+ is a meme coin on the Canto blockchain. This token is for fun only. Buyers should be aware that they are redacted if they spend too much money on this stuff.",
  },
  {
    title: "What is the peg mechanism?",
    text: "Sir, this is a meme coin.",
  },
  {
    title: "Why has the supply significantly decreased since launch?",
    text: "The unclaimed portion of the airdrop has been burned, and since transitioning to the Sustainable Ponzinomics model there were even more tokens were burned. See the medium for the full explanation"
  },
  {
    title: "Who is affected by the transfer rules?",
    text: "Transfers over 1/10th of the max transaction size that reduce address balance over 50% are flagged by the SEC for sale of unregistered securities.",
  },
  {
    title: "You've been caught moving large amounts of unregistered securities, what now?",
    text: "Your wallet balance decreased over 50% and the amount was over the threshold. You can still sell, but the SEC is watching your purchases closely. Pay the SEC to settleCase. Or buy yourself some influence so they stop prying into your business affairs - both options burn tokens. Please see token contract for a complete understanding.",
  },
  {
    title: "You're a whale, or a MEV bot, and you just want to paint the charts all day?",
    text: "You can donate to politicians as above to receive exemptions, but the common folk may not be so pleased - see below. Each use of this function decreases the cost."
  },
  {
    title: "I think jaredfromsubway.eth is a little bitch, what can I do?",
    text: "Simply being a political donor doesn't absolve you from sin. Anyone can burn tokens to run a smearCampaign against an address to remove them from the exemptions list - with caveat as below. Further, anyone can spend 1/10th the cost of gaining exemptions to increase the cost of doing so (subject to max value)."
  },
  {
    title: "I don't want to deal with these peasant games, what do?",
    text: "You can use the functions described above to repeatedly add yourself to the exemptions list, or you can pay extra to stay there. Run for president and you'll get diplomatic immunity forever on the selected address. Some of the initial supply is dedicated to CEX listings, which includes covering this fee for exchange wallets."
  },
  {
    title: "No but I'm ACTUALLY a whale?",
    text: "After 30 days, if an address manages to accrue over 5% of supply, it is given Too Big To Fail (GSIB) status - as this is likely to be a protocol. This is functionally a free but conditional diplomatic immunity."
  },


];

export default data; 